import React from 'react';
import styled from 'styled-components';

import Row from '../Basic/Row';
import Col from '../Basic/Col';
import Container from '../Basic/Container';

const SecondaryContainer = styled.div`
  background: ${({ theme }) => `linear-gradient(132deg, ${theme.gradient.first} 0%, ${theme.gradient.second} 100%)`};

  p{
    padding: 10px 0px;
    font-size: 14px;
    color: ${({ theme }) => theme.neutralColor.white};
  }

  .footer_left{
    text-align: center;
    @media ${props => props.theme.media.desktop} {
      text-align: left;
    }
  }

  .footer_right{
    text-align: center;
    @media ${props => props.theme.media.desktop} {
      text-align: right;
    }
  }
`;

const Footer = () => {
  return (
    <SecondaryContainer>
      <Container>
        <Row>
          <Col md={8}>
            <p className="footer_left">© 2021 Biuro Rachunkowe
              Beata Misztela Joanna Grabowska S.C.
              Wszystkie prawa zastrzeżone.
            </p>
          </Col>
          <Col md={4}>
            <p className="footer_right">
              Projekt i Realizacja mmisztela.pl
            </p>
          </Col>
        </Row>
      </Container>
    </SecondaryContainer>
  );
};

export default Footer;
