import * as React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from 'styled-components';

const StyledLink = styled(props => <AnchorLink {...props} />)`
  color: ${({ theme }) => theme.accentColor.primary};
  font-size: 1.125rem;
  display: block;
  font-weight: 500;

  &:hover {
    color: ${({ theme }) => theme.accentColor.tertiary};
  }

  @media ${props => props.theme.media.fablet} {
    color: ${({ theme }) => theme.neutralColor.white};
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.neutralColor.grey};
    }
  }
`;

const StyledNavLink = styled.li`
  display: inline-block;
  text-align: center;
  padding: 10px 20px;
`;

const NavLink = props => (
  <StyledNavLink>
    <StyledLink to={props.to}>{props.children}</StyledLink>
  </StyledNavLink>
);

export default NavLink;
