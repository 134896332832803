import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import 'normalize.css';
import { ThemeProvider } from 'styled-components';
import { useDarkMode } from '../../hooks/useDarkTheme';
import { lightTheme, darkTheme } from '../Theme/ThemeConfig';
import { GlobalStyles } from '../../styles/GlobalStyles';

import NavDesktop from './Navbar/NavDesktop';
import NavMobile from './Navbar/NavMobile';
import Container from '../Basic/Container';
import Row from '../Basic/Row';
import Footer from './Footer';

import ThemeContext from '../Theme/ThemeContext';

const NavHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`;

const Layout = ({ children }) => {
  const [theme, themeToggler, mountedComponent] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  if (!mountedComponent) return <div />;
  return (
    <ThemeProvider theme={themeMode}>
      <>
        <GlobalStyles />
        <NavHeader>
          <Container>
            <Row>
              <ThemeContext.Provider
                value={{ theme, themeToggler, mountedComponent }}
              >
                <NavDesktop />
                <NavMobile />
              </ThemeContext.Provider>
            </Row>
          </Container>
        </NavHeader>

        {children}
        <Footer />
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
