import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;

  @media ${props => props.theme.media.tablet} {
    width: ${({ fluid }) => fluid ? '100%' : '720px'};
  }

  @media ${props => props.theme.media.fablet} {
    width: ${({ fluid }) => fluid ? '100%' : '960px'};
  }

  @media ${props => props.theme.media.desktop} {
    width: ${({ fluid }) => fluid ? '100%' : '1140px'};
  }

  @media ${props => props.theme.media.bigDesktop} {
    width: ${({ fluid }) => fluid ? '100%' : '1320px'};
  }
`;

export default Container;
