import React from 'react';

import NavLink from './NavLink/NavLink';
import styled from 'styled-components';

const StyledNavLinks = styled.ul`
  margin: 10px 0 20px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-inline-start: 0px;

  @media ${props => props.theme.media.fablet} {
    flex-direction: row;
    margin: 10px 0 20px 0;
    margin: 0;
  }
`;

const NavLinks = () => {
  return (
    <StyledNavLinks>
      <NavLink to="/#o-nas" > O Nas </NavLink>
      <NavLink to="/#uslugi"> Usługi </NavLink>
      <NavLink to="/#cennik"> Cennik </NavLink>
      <NavLink to="/#kontakt"> Kontakt </NavLink>
    </StyledNavLinks>
  );
};

export default NavLinks;
