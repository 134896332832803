import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  &>*{
    margin-bottom: ${({ itemsMargin }) => itemsMargin ? `${itemsMargin}px` : '0px'};
  }

`;

export default Row;
