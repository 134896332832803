import React, { useState } from 'react';
import styled from 'styled-components';

import NavLinks from './NavLinks/NavLinks';
import NavToggler from './NavToggler';

import Button from '../../Basic/Button';
import Logo from '../../../images/logo.svg';

const StyledMobileNav = styled.header`
  width: 100%;
  padding: 10px 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${props => props.theme.media.fablet} {
    display: none;
  }
`;

const StyledHeader = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledNav = styled.div`
  position: absolute;
  width: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  align-self: flex-start;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 85px;
  padding: 20px;
  top: ${props => (props.toggled ? '0px' : '-1000px')};
  overflow: hidden;
  transition: top 0.4s ease-out;
  flex-direction: column;
  display: flex;


  @media ${props => props.theme.media.fablet} {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 200px;
  z-index: 10;
`;

const NavCta = styled.div`
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: solid 1px rgba(191, 191, 191, 0.4);
`;

const NavMobile = () => {
  const [toggled, setToggle] = useState(false);

  const toggleHandler = () => {
    setToggle(!toggled);
  };

  return (
    <StyledMobileNav>
      <StyledHeader>
        <StyledLogo src={Logo} alt="BiuroBMJG Logo" />
        <NavToggler toggled={toggled} clicked={() => toggleHandler()} />
      </StyledHeader>
      <StyledNav toggled={toggled} onClick={() => toggleHandler()}>
        <NavLinks />
        <NavCta>
          <Button color="primary" round="5px">
            Kontakt
          </Button>
        </NavCta>
      </StyledNav>
    </StyledMobileNav>
  );
};

export default NavMobile;
