import styled from 'styled-components';

const handleButtonColor = color => {
  switch (color) {
    case 'secondary':
      return {
        background: ({ theme }) => theme.accentColor.secondary,
        color: ({ theme }) => theme.accentColor.primary
      };
    case 'danger':
      return {
        background: ({ theme }) => theme.infoColor.danger,
        color: ({ theme }) => theme.accentColor.secondary
      };
    default:
      return {
        background: ({ theme }) => theme.accentColor.primary,
        color: ({ theme }) => theme.neutralColor.white
      };
  }
};

const handleButtonHoverColor = color => {
  switch (color) {
    case 'secondary':
      return {
        background: ({ theme }) => theme.accentColor.primary,
        color: ({ theme }) => theme.neutralColor.white
      };
    case 'danger':
      return {
        background: ({ theme }) => theme.infoColor.danger,
        color: ({ theme }) => theme.accentColor.secondary
      };
    default:
      return {
        background: ({ theme }) => theme.neutralColor.white,
        color: ({ theme }) => theme.accentColor.primary
      };
  }
};

const Button = styled.button`
  background-color: ${({ outlined, color }) =>
    outlined ? 'trasparent' : handleButtonColor(color).background};
  color: ${({ color }) => handleButtonColor(color).color};
  cursor: pointer;
  transition: 0.2s;
  width: ${({ width }) => (width ? width : 'auto')};
  height: auto;
  border: solid 2px ${({ outlined, color }) =>
    outlined ? handleButtonColor(color).color : handleButtonColor(color).background};
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.theme.button.fontSize};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : props.theme.button.fontWeight};
  padding: ${props =>
    props.padding ? props.padding : props.theme.button.padding};
  margin: ${props =>
    props.margin ? props.margin : props.theme.button.margin};
  border-radius: ${props =>
    props.round ? props.round : props.theme.button.round};
  transition: 0.2s;

  &:hover {
    color: ${({ color }) => handleButtonHoverColor(color).color};
    background-color: ${({ color }) => handleButtonHoverColor(color).background};
    border: solid 2px ${({ color }) => handleButtonHoverColor(color).color};
    transition: 0.2s;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export default Button;
