import { css } from 'styled-components';

export const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  fablet: '992px',
  desktop: '1200px',
  bigDesktop: '1400px',
};

const themeConfig = {
  fontFamily: '"Poppins", sans-serif',
  secondaryFontFamily: '',
  shadow: '',
  spacing: {
    sectionMargins: css`
      margin-top: 100px;
      margin-bottom: 100px;
    `,
  },
  button: {
    round: '5px',
    padding: '15px 28px',
    margin: '30px 0px',
    fontWeight: '500',
    fontSize: '16px',
  },
  media: {
    mobile: `(min-width: ${breakpoints.mobile})`,
    tablet: `(min-width: ${breakpoints.tablet})`,
    fablet: `(min-width: ${breakpoints.fablet})`,
    desktop: `(min-width: ${breakpoints.desktop})`,
    bigDesktop: `(min-width: ${breakpoints.bigDesktop})`,
    maxMobile: `(max-width: ${breakpoints.mobile})`,
    maxTablet: `(max-width: ${breakpoints.tablet})`,
  },
};

export const darkTheme = {
  accentColor: {
    primary: '#00FFAA',
    secondary: '#00BBFF',
    tertiary: '#4579F5',
  },
  neutralColor: {
    white: '#fff',
    grey: '#B1B1B1',
    textPrimary: '#E0E0FF',
    textSecondary: '#202036',
    shadow: '#1B0A52'
  },
  backgroundColor: {
    primary: '#27273E',
    secondary: '#202036',
    tertiary: '#33334B'
  },
  infoColor: {
    danger: '#e63946'
  },
  gradient: {
    first: '#0088BC',
    second: '#00A8E8'
  },
  ...themeConfig,
};

export const lightTheme = {
  accentColor: {
    primary: '#0088BC',
    secondary: '#FFFFFF',
    tertiary: '#003459',
  },
  neutralColor: {
    white: '#FFFFFF',
    grey: '#C1C1C1',
    textPrimary: '#707070',
    textSecondary: '#0088BC',
    shadow: '#1B0A52'
  },
  backgroundColor: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    tertiary: '#FFFFFF'
  },
  infoColor: {
    danger: '#e63946'
  },
  gradient: {
    first: '#0088BC',
    second: '#00A8E8'
  },
  ...themeConfig,
};
